@use "sass:math";
@import "../../../styles/base/abstracts";

// These styles were taken from http://player.kaltura.com/docs/responsive

// defines maximum space the player can take
.hbs-kaltura-embed {
  background-color: $c-text;
  display: inline-block;
  height: auto;
  position: relative;
  vertical-align: middle;
  width: 100%;
}

// inner pusher defines aspect ratio: in this case 2:3 ~ 66.666%
.hbs-kaltura-embed__responsive-spacer {
  margin-top: 66.666%;
}

// the player embed target, set to take up available absolute space
.hbs-kaltura-embed__player {
  @include absolute(0);
}

.kWidgetPlayBtn:hover {
  @include theme-light;
  background: $kaltura-gradient rgba(0, 0, 0, 0.2) !important;
}

.kWidgetPlayBtn.kWidgetPlayBtn {
  // Reset default styles
  // ---------------------------------------------
  @include margin(0);
  @include relative(auto);
  @include size(auto);
  background: none;

  @include hover {
    background: none;
  }

  // HBS custom styles
  // ---------------------------------------------
  @include absolute(0);
  @include size(100%);
  transition: background-color $transition;
  background-image: $kaltura-gradient;
  z-index: 1;

  // Play button box
  &::before {
    @include pseudo(x x 0 0);
    @include margin($spacing-sm);
    @include size($kaltura-play-btn-size);
    background-color: $c-white;
    border-radius: $border-radius;
    transition: background-color $transition;
  }

  // Play button icon
  &::after {
    @include pseudo(x x 0 0);
    @include size(0);
    border: 10px solid transparent;
    border-left-width: 15px;
    border-left-color: $c-black;
    margin-left: 6px;
    margin-bottom: 1px;
    transform: translate(
      math.div($kaltura-play-btn-size, 2),
      math.div($kaltura-play-btn-size, -2)
    );
    transition: border-color $transition;
  }

  @include hover {
    &::before {
      background-color: $c-spot;
    }

    &::after {
      border-left-color: $c-white;
    }
  }

  &:focus:focus-visible {
    outline-offset: 4px !important;
  }
}

.hbs-kaltura-embed__player img.kWidgetCentered {
  @include size(100%);
  object-fit: cover;
}

.hbs-kaltura-embed {
  height: 100%;
  p {
    @include baseline-text;
  }
}

.hbs-media-asset:has(.p3sdk-container) {
  padding-bottom: 37px;
}

// three play audio embed style fixes (global styles are overwriting)
.p3sdk-audio-description-component {
  max-height: 40px !important;
  padding: 5px 5px 2px 5px !important;
  border: none;

  .mycroft-audio-description-widget {
    display: flex;
    flex-flow: row;
    align-items: center;
    border-radius: 0 !important;

    .mycroft-audio-description-toggle {
      display: block;
      padding: 0 16px 0 12px;
    }

    button,
    input,
    textarea,
    select {
      margin: 0px;
      padding: 0px;
      font: inherit;
      appearance: auto;
      -webkit-appearance: auto;
    }
  }
}

@import "../../../styles/base/abstracts";

.hbs-embed-wrapper {
  @include size(100%);
  position: relative;
}
.hbs-embed {
  max-width: 100%;
}

.hbs-embed-wrapper--aspect-ratio {
  .hbs-embed {
    @include absolute(0);
  }
}

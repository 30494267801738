@use "sass:math";
@import "../../../styles/base/abstracts";

@keyframes bg-fade-in {
  from {
    background-color: transparent;
  }

  to {
    background-color: transparentize($c-black, 0.1);
  }
}

@keyframes opacity-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

$close-btn-size: clamp(30px, 3vw, 50px);

.hbs-media-asset {
  position: relative;

  img,
  video {
    @include size(100%, auto);
    opacity: 0;
    transition:
      opacity $transition-sm,
      transform $transition-sm;

    &.kWidgetCentered {
      opacity: 1;
    }
  }

  figcaption {
    @include padding($spacing-xs x);
    background-color: $c-bg;
    line-height: 0;

    p,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      @include small-text($ff-sans, $fw-reg);
      line-height: $lh-base;
      margin-bottom: 0;
      strong {
        font-weight: $fw-semibold;
      }
    }

    .hbs-cta-link--link {
      display: block;
      border-top: $border-light;
      margin-top: $spacing-md;
      padding-top: $spacing-md;
    }
  }
}

.hbs-media-asset.with-backup-image {
  video {
    opacity: 1;
  }
}

.hbs-media-asset--loaded {
  background-color: transparent;

  img,
  video {
    opacity: 1;
    transform: scale(1);
  }

  figcaption {
    opacity: 1;
  }
}

.hbs-media-asset__wrapper {
  background-color: $c-border-light;
  display: flex;
  overflow: hidden;
  position: relative;
}

.hbs-media-asset--no-aspect-ratio {
  .hbs-media-asset__wrapper {
    background-color: transparent;

    img {
      max-height: 14.75vw;
      width: initial;
    }
  }
}

.hbs-media-asset--logo {
  width: 50%;

  .hbs-media-asset__wrapper {
    background-color: transparent;
  }
}

.hbs-media-asset--image,
.hbs-media-asset--video {
  &.align-wide {
    @include mq($bp-desktop) {
      margin-right: calc(
        #{math.div($col-3, $col-9) * -100%} - #{math.div($grid-padding, 3) * 2}
      );
    }

    @include mq($bp-desktop-xl) {
      margin-right: 0;
      margin-right: calc(
        #{math.div($col-4, $col-8) * -100%} - #{$grid-padding}
      );
    }
  }

  &.align-full {
    @include margin(x $grid-padding-mobile * -2);

    figcaption {
      @include grid-parent;
      @include site-max-width;
    }

    .hbs-media-asset__caption-text {
      @include grid-child;
    }

    @include mq($bp-tablet) {
      margin-left: calc(#{$col-16} - (100vw - #{$grid-padding * 2}));
      margin-right: $grid-padding * -2;
    }

    @include mq($bp-desktop) {
      margin-left: calc(#{math.div($col-8, $col-9) * 50%} - 50vw - 2px);
      margin-right: calc(#{math.div($col-3, $col-9) * -100%} - 2.6666vw);
    }

    @include mq($bp-desktop-xl) {
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
    }
  }

  &.align-wide,
  &.align-center,
  &.align-full {
    @include margin($spacing-lg x);

    @include mq($bp-desktop) {
      @include margin($spacing-xl x);
    }
  }
}

.hbs-media-asset__pause {
  @include absolute($spacing-sm $spacing-sm x x);
  @include padding($spacing-xs);
  @include small-text($font-weight: $fw-semibold);
  @include size(45px);
  aspect-ratio: 1/1;
  background-color: $c-black;
  border-radius: $border-radius;
  color: $c-white;
  line-height: 0;
  text-align: center;
  z-index: $z-index-1;

  @include hover {
    color: $c-black;
    background-color: $c-white;
  }

  video:hover + & {
    color: $c-black;
    background-color: $c-white;
  }
}

.hbs-media-asset__pause-icon {
  @include size(14px);
}

.hbs-media-asset__kaltura-text {
  @include absolute(
    x x calc(#{$kaltura-play-btn-size} / 2)
      calc(#{$kaltura-play-btn-size} + #{$spacing-sm})
  );
  @include baseline-text($ff-sans, $fw-semibold);
  @include theme-black;
  margin-left: $spacing-md;
  color: $c-text;
  text-shadow:
    0 0 1em $c-bg,
    0 0 1.5em $c-bg;
  z-index: 1;
  pointer-events: none;
}

.hbs-media-asset__kaltura-text-prompt {
  position: relative;
  z-index: $z-index-1;
}

.hbs-media-asset__kaltura-duration {
  color: $c-text-lighter;
  margin-left: $spacing-xxxs;
  position: relative;
  z-index: $z-index-1;
}

// Kaltura Video Modal
// ---------------------------------------------

.hbs-media-asset__modal-trigger {
  position: relative;
  transition: background-color $transition;
  background-image: $kaltura-gradient;

  // Play button box
  &::before {
    @include pseudo(x x 0 0);
    @include margin($spacing-sm);
    @include size($kaltura-play-btn-size);
    background-color: $c-white;
    border-radius: $border-radius;
    transition: background-color $transition;
  }

  // Play button icon
  &::after {
    @include pseudo(x x 0 0);
    @include size(0);
    border: 10px solid transparent;
    border-left-width: 15px;
    border-left-color: $c-black;
    margin-left: 6px;
    margin-bottom: 1px;
    transform: translate(
      math.div($kaltura-play-btn-size, 2),
      math.div($kaltura-play-btn-size, -2)
    );
    transition: border-color $transition;
  }

  @include hover {
    background: $kaltura-gradient rgba(0, 0, 0, 0.2);

    &::before {
      background-color: $c-spot;
    }

    &::after {
      border-left-color: $c-white;
    }
  }
}

.hbs-media-asset__modal-trigger-wrapper {
  height: 100%;
  position: relative;
  min-height: 100%;
  z-index: 1;

  img,
  video {
    position: relative;
    z-index: -1;
  }
  button:focus {
    outline-offset: 5px !important;
  }
  button::before {
    @include theme-light;
  }
}

.hbs-media-asset__modal-overlay {
  @include fixed(0);
  animation: bg-fade-in $t-time $t-ease-in-out forwards;
  z-index: 3147483647; //Harvy-chatbot is 2147483647;
}

.hbs-media-asset__modal-content {
  @include fixed(50% x x 50%);
  animation: opacity-fade-in $t-time $t-time $t-ease-in-out forwards;
  opacity: 0;
  transform: translate(-50%, -50%);
}

.hbs-media-asset__modal-content-inner {
  width: calc(80vw - 175px);
  figcaption {
    @include padding($spacing-xs $spacing-xxs);
  }
}

@include mq($bp-mobile, $bp-tablet) {
  .hbs-media-asset__modal-content {
    width: 80%;
  }
  .hbs-media-asset__modal-content-inner {
    width: 100%;
  }
}

.hbs-media-asset__modal-content-close {
  @include size($close-btn-size);
  @include absolute(0 0 x x);
  align-items: center;
  background-color: $c-charcoal;
  border-radius: $border-radius;
  color: $c-white;
  display: flex;
  font-size: 20px;
  justify-content: center;
  transform: translate(50%, -50%);
  transition:
    background-color $transition,
    color $transition;
  z-index: $z-index-1;

  @include hover {
    background-color: $c-white;
    color: $c-black;
  }
}

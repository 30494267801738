@import "../../../styles/base/abstracts";

// default
#hbs-wowza-video.video-js {
  width: 100% !important;
  height: auto !important;

  .vjs-tech {
    position: static !important;
  }
  video {
    opacity: 1 !important;
  }
}
// needed for caption spacing
.hbs-topper.hbs-statistics-topper.hbs-wowza-embed-topper {
  align-items: end;
  @include mq($bp-desktop-lg, $bp-desktop-xl) {
    .hbs-statistics-topper__statistics {
      padding-top: $spacing-xxxl;
    }
  }
}
